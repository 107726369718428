<template>
  <div class="activity-wrapper">
    <div
      class="line_wrapper cursorP loads"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中.."
    >
      <div
        class="line_box"
        v-for="item in onlineList"
        :key="item.id"
        @click="goRouter(item.webUrl)"
      >
        <div class="imgs">
          <img :src="item.imgUrl" alt="" class="err_image" />
        </div>
        <div class="contents">
          <div class="content_top">
            <p>{{ item.name }}</p>
          </div>
          <div class="content_foot">
            活动时间：
            <span>{{ item.begenTime }} 至 {{ item.endTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <status
      type="post"
      des="暂无活动"
      v-if="onlineList == null || onlineList.length <= 0"
    ></status>
  </div>
</template>

<script>
export default {
  name: "inline",
  data() {
    return {
      ongoingList: [{ status: 2 }],
      onlineList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 6,
      },
      loading: false,
    };
  },
  filters: {
    getStatus(key) {
      let status = "";
      switch (key) {
        case 0:
          status = "进行中";
          break;
        case 1:
          status = "已结束";
          break;
        case 2:
          status = "报名中";
          break;
      }
      return status;
    },
  },
  methods: {
    // 请求活动接口
    async inlineActivityList() {
      this.loading = true;
      let res = await this.$api.inlineActivityList(this.queryParams);
      console.log(res);
      if (res.data.success) {
        this.loading = false;
        this.onlineList = res.data.data.records;
      }
    },
    goRouter(url, a) {
      console.log(url);
      window.open(url, "_blank");
    },
  },
  created() {
    this.inlineActivityList();
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/activity.less";
</style>